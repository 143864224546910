var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"ma-0 pt-6",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-row',[_c('export-excel',{staticClass:"mx-1 my-1",staticStyle:{"width":"unset"},attrs:{"data":_vm.exportarDataExcel(),"name":_vm.nombreReporte + '.xls',"before-generate":function () {
              _vm.exportandoExel = true
            },"before-finish":function () {
              _vm.exportandoExel = false
            }}},[(_vm.exportar.includes('EXCEL'))?_c('v-btn',{attrs:{"color":"success","small":"","disabled":_vm.datos.length == 0}},[_vm._v(" EXCEL "),_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFileExcel))])],1):_vm._e()],1),(_vm.exportar.includes('PDF'))?_c('v-btn',{staticClass:"mx-1 my-1",attrs:{"color":"error","small":"","disabled":_vm.datos.length == 0},on:{"click":function($event){return _vm.ImprimirPDF()}}},[_vm._v(" PDF "),_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFilePdf))])],1):_vm._e(),_c('div',{staticClass:"mx-1 mt-1"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"indigo"}},'v-btn',attrs,false),on),[_vm._v(" Columnas ")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.columasSelect),callback:function ($$v) {_vm.columasSelect=$$v},expression:"columasSelect"}},[_vm._l((_vm.headers),function(item,i){return [_c('v-list-item',{key:("item-" + i),attrs:{"value":item,"active-class":"primary--text  "},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary  "}})],1)]}}],null,true)})]})],2)],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[(_vm.datos.length > 0)?_c('v-text-field',{attrs:{"clearable":"","outlined":"","dense":"","append-icon":_vm.icons.mdiMagnify,"label":"Filtrar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","search":_vm.search,"no-data-text":"No hay data disponible","no-results-text":"No hay data disponible","headers":_vm.columasSelect,"items":_vm.datos,"items-per-page":5},scopedSlots:_vm._u([{key:"body",fn:function(ref){
            var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.value},_vm._l((_vm.columasSelect),function(col){return _c('td',{key:col.value},[(col.format == undefined)?_c('span',[_vm._v(_vm._s(item[col.value]))]):(col.format.small == true)?_c('small',[_vm._v(_vm._s(item[col.value]))]):(col.format.chip == true)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":col.format.color[col.format.valores.indexOf(item[col.value])],"small":col.format.size == 'small',"x-small":col.format.size == 'x-small'}},[_vm._v(" "+_vm._s(item[col.value])+" ")]):_vm._e(),(col.value == 'accion')?_c('div',[(_vm.acciones.includes('VER'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"pr-2",on:{"click":function($event){return _vm.GetVer(item)}}},'a',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.icons.mdiEye))])],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])]):_vm._e(),(_vm.acciones.includes('EDITAR'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"pr-2",on:{"click":function($event){return _vm.GetEditar(item)}}},'a',attrs,false),on),[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.icons.mdiPencil))])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]):_vm._e(),(_vm.acciones.includes('ELIMINAR'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"pr-2",on:{"click":function($event){return _vm.GetEliminar(item)}}},'a',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(_vm._s(_vm.icons.mdiDelete))])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]):_vm._e()],1):_vm._e()],1)}),0)}),0)]}}])})],1)],1),_c('pdf',{ref:"pdfRef"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }