<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="6" class="ma-0 pt-6">
        <v-row>
          <export-excel
            class="mx-1 my-1"
            :data="exportarDataExcel()"
            :name="nombreReporte + '.xls'"
            :before-generate="
              () => {
                exportandoExel = true
              }
            "
            :before-finish="
              () => {
                exportandoExel = false
              }
            "
            style="width: unset"
          >
            <v-btn  color="success" small v-if="exportar.includes('EXCEL')" :disabled="datos.length == 0">
              EXCEL
              <v-icon>{{ icons.mdiFileExcel }}</v-icon>
            </v-btn>
          </export-excel>
          <v-btn   color="error" small class="mx-1 my-1" v-if="exportar.includes('PDF')" :disabled="datos.length == 0" @click="ImprimirPDF()">
            PDF
            <v-icon>{{ icons.mdiFilePdf }}</v-icon>
          </v-btn>
          <div class="mx-1 mt-1">
            <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small color="indigo" v-bind="attrs" v-on="on"> Columnas </v-btn>
              </template>

              <v-card>
                <v-list>
                  <v-list-item-group v-model="columasSelect" multiple>
                    <template v-for="(item, i) in headers">
                      <v-list-item :key="`item-${i}`" :value="item" active-class="primary--text  ">
                        <template v-slot:default="{ active }">
                          <v-list-item-content>
                            <v-list-item-title v-text="item.text"></v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-checkbox :input-value="active" color="primary  "> </v-checkbox>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          clearable
          outlined
          dense
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Filtrar"
          v-if="datos.length > 0"
          single-line
          hide-details
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
         dense
          :search="search"
          no-data-text="No hay data disponible"
          no-results-text="No hay data disponible"
          :headers="columasSelect"
          :items="datos"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.value">
                <td v-for="col in columasSelect" :key="col.value">
                  <span v-if="col.format == undefined">{{ item[col.value] }}</span>
                  <small v-else-if="col.format.small == true">{{ item[col.value] }}</small>

                  <v-chip
                    v-else-if="col.format.chip == true"
                    class="ma-2"
                    :color="col.format.color[col.format.valores.indexOf(item[col.value])]"
                    :small="col.format.size == 'small'"
                    :x-small="col.format.size == 'x-small'"
                  >
                    {{ item[col.value] }}
                  </v-chip>

                  <div v-if="col.value == 'accion'">
                    <v-tooltip bottom v-if="acciones.includes('VER')">
                      <template v-slot:activator="{ on, attrs }">
                        <a class="pr-2" v-bind="attrs" v-on="on" @click="GetVer(item)">
                          <v-icon color="primary">{{ icons.mdiEye }}</v-icon>
                        </a>
                      </template>
                      <span>Ver</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="acciones.includes('EDITAR')">
                      <template v-slot:activator="{ on, attrs }">
                        <a class="pr-2" v-bind="attrs" v-on="on" @click="GetEditar(item)">
                          <v-icon color="info">{{ icons.mdiPencil }}</v-icon>
                        </a>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="acciones.includes('ELIMINAR')">
                      <template v-slot:activator="{ on, attrs }">
                        <a class="pr-2" v-bind="attrs" v-on="on" @click="GetEliminar(item)">
                          <v-icon color="error">{{ icons.mdiDelete }}</v-icon>
                        </a>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <pdf ref="pdfRef"></pdf>
  </div>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiFileExcel, mdiFilePdf, mdiMagnify, mdiPencil, mdiDelete, mdiEye } from '@mdi/js'

import Pdf from './Pdf.vue'
export default {
  components: {
    Pdf,
  },
  props: {
    exportar: Array,
    datosIn: Array,
    nombreReporte: String,
    cabeceraIn: Array,
    acciones: Array,
  },
  setup(props, contex) {
    const menu = ref(null)
    const headers = ref([])
    const datos = ref()
    const pdfRef = ref(null)
    const columasSelect = ref([])
    const exportandoExel = ref(false)
    const search = ref('')
    const exportarDataExcel = () => {
      console.log(columasSelect.value)

      let data = []
      datos.value.forEach(element => {
        let objet = {}
        columasSelect.value.forEach(columna => {
          if (typeof element[columna.value] === 'number') {
            objet = { ...objet, [columna.text]: element[columna.value].toString().replace('.', ',') }
          } else {
            objet = { ...objet, [columna.text]: element[columna.value] }
          }
        })
        data.push(objet)
      })
      console.log(data)
      return data
    }
    watch(props, () => {
      headers.value = props.cabeceraIn
      datos.value = props.datosIn
      columasSelect.value = props.cabeceraIn.filter(item => item.visible != false)
    })
    onBeforeMount(() => {
      headers.value = props.cabeceraIn
      datos.value = props.datosIn

      if (props.acciones != undefined) {
        if (headers.value.find(item => item.value == 'accion') == undefined) {
          headers.value.push({ text: 'Accion', value: 'accion', visible: true })
        }
      }
      columasSelect.value = props.cabeceraIn.filter(item => item.visible != false)
    })

    const GetEditar = item => {
      contex.emit('GetEditar', item)
    }

    const GetVer = item => {
      contex.emit('GetVer', item)
    }
    const GetEliminar = item => {
      contex.emit('GetEliminar', item)
    }

    const ImprimirPDF = () => {
      const bodyPdf = []
      const headPdf = []

      columasSelect.value.forEach(element => {
        headPdf.push({ content: element.text })
      })

      datos.value.forEach(element => {
        let arr = []
        columasSelect.value.forEach(columna => {
          arr.push(element[columna.value])
        })

        bodyPdf.push(arr)
      })

      const datosInit = {
        nombreDocumento: props.nombreReporte,
        orientacion:'l',
        cabecera1: {
          visible: true,
        },
        titulo: {
          visible: true,
          posicion: 'center',
          texto: props.nombreReporte,
          TamanoLetras: 12,
          color: '#7B7B7B',
        },
        cabecera2: {
          visible: false,
          datos: [{ texto: 'Recibo #', TamanoLetras: 14, color: '#FF0000' }, { texto: 'Fecha: ' }, { texto: 'Hora: ' }],
        },
        sub1: {
          visible: false,
          datos: [],
        },
        sub2: {
          visible: false,
          datos: [],
        },
        cuerpo1: {
          visible: true,
          datos: [
            {
              titulo: { texto: 'Datos', TamanoLetras: 12 },
              tablas: {},
              head: [headPdf],
              body: bodyPdf,
            },
          ],
        },
      }

      pdfRef.value.generarPDF(datosInit)
    }

    return {
      icons: {
        mdiFileExcel,
        mdiFilePdf,
        mdiMagnify,
        mdiPencil,
        mdiDelete,
        mdiEye,
      },
      headers,
      datos,
      columasSelect,
      menu,
      exportarDataExcel,
      exportandoExel,
      search,
      GetEditar,
      GetVer,
      GetEliminar,
      pdfRef,
      ImprimirPDF,
    }
  },
}
</script>