<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px" scrollable>
      <v-card>
        <v-card-subtitle>
          <v-row class="  ">
            <v-col lg="6" md="8" sm="12" cols="12">
              <cabecera-empresa></cabecera-empresa>
            </v-col>
            <v-col lg="6" md="4" sm="12" cols="12">
              <div>
                <span class="m-0 pt-6"> Cliente: {{ datosIN.nombre }} {{ datosIN.apellido }}</span>
                <br />
                
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-card-subtitle>
        <v-card-text>
          <v-row class="mb-4">
            <v-col>
              <div>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Comanda</th>
                        <th class="text-left">Fecha</th>
                        <th>Descripcion</th>
                        <th class="text-left">Total</th>
                        <th class="text-left">Abonos</th>
                        <th class="text-left">Resta</th>
                        <th class="text-left">Accion</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in datos" :key="index">
                        <td>{{ item.id }}</td>
                        <td>{{ item.fecha }}</td>
                        <td>{{ item.cliente }}</td>
                        <td>{{ item.total_por_cobrar }}</td>
                        <td>{{ item.total_pago_por_cobrar }}</td>
                        <td>{{ item.total_por_cobrar - item.total_pago_por_cobrar }}</td>
                        <td>
                          <a class="pr-2" @click="AbrirModalComanda(item)">
                            <v-icon color="primary">{{ icons.mdiEye }}</v-icon>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-col>
            <v-col cols="7"> </v-col>
            <v-col>
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>Nro comandas</td>
                      <td class="text-right">{{ datosIN.total_comandas.toFixed() }}</td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td class="text-right">{{ datosIN.total.toFixed(2) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small class="primary" @click="generarPDF()">PDF</v-btn>
          <v-btn small color="secondary"   @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <modal-ver-comanda
      :comanda="comandaSelect"
      :soloVer="true"
      :openModal="openModalComanda"
      @GetOpenModal="GetOpenModalComanda"
    ></modal-ver-comanda>
    <PdfFormatoLibreDocumentos ref="PdfFormatoLibreDocumentosRef" />
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import CabeceraEmpresa from '@/components/CabeceraEmpresa.vue'
import { mdiEye } from '@mdi/js'
import ModalVerComanda from '@/views/sistema/administracion/dashboard/componentes/ModalVerComanda.vue'
import PdfFormatoLibreDocumentos from '@/components/PdfFormatoLibreDocumentos.vue'
import moment from 'moment'
import FuncionesGenerales  from '@/funciones/funciones'
export default {
  components: {
    CabeceraEmpresa,
    ModalVerComanda,
    PdfFormatoLibreDocumentos,
  },
  setup(props) {
    const PdfFormatoLibreDocumentosRef = ref(false)
    const dialog = ref(false)
    const datosIN = ref({})
    const datos = ref([])
    const comandaSelect = ref({})
    const openModalComanda = ref(false)
    const AbrirModalComanda = datos => {
      openModalComanda.value = true
      comandaSelect.value = datos
    }
    const GetOpenModalComanda = datos => {
      openModalComanda.value = false
    }
    const abrirComanda = datos => {
      datosIN.value = datos
      dialog.value = true
      buscarComandas()
    }

    const generarPDF = (_tipo = 1) => {
      const datoInit = datos.value
      const cuerpoTablaDetalles = []

      datoInit.forEach(item => {
         
          cuerpoTablaDetalles.push([
            item.id.toString(),
            item.fecha, 
            item.cliente, 
            '$' + FuncionesGenerales.formatoNumeric(item.total_por_cobrar),
          ])
       
      })

      const datosInit = {
        cabecera1: { visible: true },
        nombreDocumento: `Cuentas por cobrar`,

        numeroDocumento: {
          texto: 'Cuentas por cobrar',
          valor: '',
        },
        numeroControl: {
          texto: '',
          valor: '',
        },
        numeroFecha: {
          texto: 'Fecha',
          valor: moment().format('YYYY-MM-DD'),
        },
        sub1: {
          linea1: [
            { texto: 'Cliente:', valor: datosIN.value.nombre },
            { texto: 'Rif:', valor: datosIN.value.documento },
            { texto: 'Telefono:', valor: '' },
            { texto: 'Direccion:', valor: '' },
          ],
          linea2: [],
        },
        sub2: {
          linea1: [],
          linea2: [],
        },

        pie: {
          nota: {
            texto: 'Descripcion:',
            valor: 'Facturas pendientes hasta ' + moment().format('DD-MM-YYYY'),
          },
          totales: [
            {
              texto: 'Nro facturas:',
              valor: datosIN.value.total_comandas.toFixed(2),
            },
            {
              texto: 'Total:',
              valor: '$' + datosIN.value.total.toFixed(2),
            },
          ],
        },

        detalles: {
          cabecera: [
            { texto: 'Documento', posicion: '45', alineacion: 'left' },
            { texto: 'Fecha', posicion: '120', alineacion: 'left' },
            {
              texto: 'Descripcion',
              posicion: '220',
              alineacion: 'left',
              justificar: { maxWidth: 300, align: 'justify', maximoItems: 75 },
            },
            
            { texto: 'Total', posicion: '555', alineacion: 'right' },
          ],
          cuerpo: cuerpoTablaDetalles,
        },
      }

      PdfFormatoLibreDocumentosRef.value.generarPDF(datosInit)
    }
    const cargando = ref(false)
    const buscarComandas = () => {
      cargando.value = true
      MaestroService.ReporteCXCClienteGrupo({
        id_cliente: datosIN.value.id_cliente,
        id_grupo_negocio: datosIN.value.id_grupo_negocio,
      })
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = response.data.datos
          }
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'buscarComandas',
          })
        })
        .finally(() => {
          cargando.value = false
        })
    }
    return {
      dialog,
      abrirComanda,
      datosIN,
      buscarComandas,
      datos,
      cargando,
      icons: {
        mdiEye,
      },
      AbrirModalComanda,
      GetOpenModalComanda,
      openModalComanda,
      comandaSelect,
      generarPDF,
      PdfFormatoLibreDocumentosRef
    }
  },
}
</script>